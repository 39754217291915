const countdownTimer = document.getElementById("countdown-timer");


if(countdownTimer){
	const updateCountdown = () => {
           let timeRemaining = countdownTimer.dataset.timerem;
           if (timeRemaining <= 0) {
             return; // No need to run the countdown if timeRemaining is zero or negative
           }

        const updateDisplay = () => {
          const days = Math.floor(timeRemaining / (60 * 60 * 24));
          const hours = Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60));
          const minutes = Math.floor((timeRemaining % (60 * 60)) / 60);
          const seconds = timeRemaining % 60;

          countdownTimer.innerHTML = `
            <div class="counting" id="days">
              <span class="time">${days}</span>
              <span class="text">(jours)</span>
            </div>
            <div class="counting" id="hours">
              <span class="time">${hours}</span>
              <span class="text">(heures)</span>
            </div>
            <div class="counting" id="minutes">
              <span class="time">${minutes}</span>
              <span class="text">(minutes)</span>
            </div>
            <div class="counting" id="seconds">
              <span class="time">${seconds}</span>
              <span class="text">(secondes)</span>
            </div>
          `;
        };

        const countdownInterval = setInterval(() => {
          if (timeRemaining <= 0) {
             location.reload();
          } else {
            updateDisplay();
            timeRemaining--;
          }
        }, 1000);
      };

      document.addEventListener("DOMContentLoaded", () => {
        updateCountdown();
      });
}
